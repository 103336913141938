var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[(_vm.showAlert)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" Active Visit: "),_c('strong',[_vm._v(_vm._s(_vm.visitStore.visitData.rxDate))])]):_vm._e(),_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_vm._v(" Active Visit: "+_vm._s(_vm.visitStore.visitData.rxDate)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getAllVisists}},[_vm._v(" Refresh ")])],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"single-select":"","item-key":"id","items":_vm.visitList.data,"hide-default-footer":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectVisit(item, index)}}},[(index === _vm.selectedIndex)?_c('v-icon',{attrs:{"color":"info","medium":""},on:{"click":function($event){return _vm.selectVisit(item, index)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxIntermediate)+" ")]):_vm._e(),(index !== _vm.selectedIndex)?_c('v-icon',{attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.selectVisit(item, index)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxBlankOutline)+" ")]):_vm._e()],1)]}},{key:"item.transferId",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.selectVisitRoute(item, index)}}},[_c('v-icon',{attrs:{"color":"info","medium":""},on:{"click":function($event){return _vm.selectVisitRoute(item, index)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFax)+" ")])],1)]}},{key:"item.status[3].value",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"project-progress mt-1",attrs:{"height":"6","rounded":"","color":_vm.resolveUserProgressVariant(item.status[3].value),"value":item.status[3].value}},[_c('span',[_vm._v(_vm._s(item.status[3].value))])])]}},_vm._l((_vm.tableColumnHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
        )),function(header){return {key:("item." + (header.value)),fn:function(ref){
        var header = ref.header;
        var value = ref.value;
return _vm._l((header.formatter(value)),function(item,index){return _c('v-list-item',{key:item},[_c('v-list-item-content',[_vm._v(" "+_vm._s(index+1)+": "+_vm._s(item)+" ")])],1)})}}})],null,true),model:{value:(_vm.selectedVisit),callback:function ($$v) {_vm.selectedVisit=$$v},expression:"selectedVisit"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }