<template>
  <div class="user-tab-security">
    <insurnace-tab></insurnace-tab>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import insurnaceTab from '@/components/Forms/insurnaceTab.vue'

export default {
    name: 'UserTabUploads',
    components: {
        insurnaceTab
    },
    setup() {
        return {

        }
    },
}
</script>
