<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <!-- <v-card-title class="justify-center text-h5">
      Update your information
    </v-card-title>
    <v-card-text class="text-center mt-n2">
      Updating user details will receive a privacy audit.
    </v-card-text> -->

      <v-card-text class="mt-5">
        <v-form
          class="multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.firstName"
                disabled
                outlined
                dense
                label="First Name"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.lastName"
                outlined
                disabled
                dense
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.dateofBirth"
                outlined
                dense
                disabled
                label="Date of Birth"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.healthCardNumber"
                outlined
                disabled
                dense
                label="Health Card Number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.phoneNumber"
                outlined
                dense
                label="Phone Number"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.email"
                outlined
                dense
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <p>Please Type your address below</p>
              <v-text-field
                id="autocompleteNew"
                v-model="userDataLocal.addressAuto"
                outlined
                dense
                label=""
                autocomplete="nope"
                clearable
              ></v-text-field>
            </v-col>
            <!-- <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="userDataLocal.address"
              outlined
              dense
              disabled
              label=""
              clearable
            ></v-text-field>
          </v-col> -->

            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.unit"
                outlined
                dense
                label="Unit #"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.street_number"
                outlined
                dense
                label="Street #"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.route"
                outlined
                dense
                label="Street Name"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.locality"
                outlined
                dense
                label="City"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.province"
                outlined
                dense
                label="Province"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.postal_code"
                outlined
                dense
                label="Postal Code"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                :key="refreshKey"
                v-model="userDataLocal.country"
                outlined
                dense
                disabled
                label="Country"
                @change="generatedFormattedAddress"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="closeDialog"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { Loader } from '@googlemaps/js-api-loader'
import { visitService } from '@/api/index'
import { useVisitStore } from '@/stores/Visit'
import { splitFullName } from '@/util/functions/index'

export default {
    name: 'UserBioEdit',
    props: {
        isBioDialogOpen: {
            type: Boolean,
            required: true,

            // default: true
        },

    // userData: {
    //     type: Object,
    //     required: true,
    // },
    },
    data() {
        return {
            refreshKey: 0,
            visitId: this.visitStore.visitId,
            dob: this.visitStore.dob,

            // autoAddress: this.visitStore.patientData.address,
            address_components: [],
            autoAddress: '',
            userDataLocal: {
                firstName: '',
                lastName: '',
                dateofBirth: '',
                healthCardNumber: '',
                phoneNumber: '',
                email: '',
                formattedAddress: '',

                // addressAuto: '',
                // address: '',
                unit: '',
                street_number: '',
                route: '',
                locality: '',
                province: '',
                postal_code: '',
                country: '',
                lastUpdated: '',
            },
        }
    },
    setup() {
        const visitStore = useVisitStore()
        const timeStamp = new Date(Date.now()).toISOString()

        return {
            visitStore,
            timeStamp
        }
    },
    computed: {
        patient() {
            return splitFullName(this.visitStore.patientData.name)
        },
        patientData() {
            return this.visitStore.patientData
        },
    },
    watch: {
        isBioDialogOpen(val) {
            if (val) {
                if (!this.patientData.hasOwnProperty('detailedAddress')) {
                    this.userDataLocal = {
                        firstName: this.patient.FirstName ? this.patient.FirstName : '',
                        lastName: this.patient.LastName ? this.patient.LastName : '',
                        dateofBirth: this.patientData.dateofBirth ? this.patientData.dateofBirth : '',
                        healthCardNumber: this.patientData.healthCardNumber ? this.patientData.healthCardNumber : '',
                        phoneNumber: this.patientData.phoneNumber ? this.patientData.phoneNumber : '',
                        email: this.patientData.email ? this.patientData.email : '',
                        country: '',
                        street_number: '',
                        route: '',
                        locality: '',
                        province: '',
                        postal_code: '',
                        unit: '',

                    }
                } else {
                    this.userDataLocal = {
                    // ...this.patientData,
                        firstName: this.patient.FirstName ? this.patient.FirstName : '',
                        lastName: this.patient.LastName ? this.patient.LastName : '',
                        dateofBirth: this.patientData.dateofBirth ? this.patientData.dateofBirth : '',
                        healthCardNumber: this.patientData.healthCardNumber ? this.patientData.healthCardNumber : '',
                        phoneNumber: this.patientData.phoneNumber ? this.patientData.phoneNumber : '',
                        email: this.patientData.email ? this.patientData.email : '',
                        country: this.patientData.detailedAddress.country ? this.patientData.detailedAddress.country : '',
                        street_number: this.patientData.detailedAddress.street_number ? this.patientData.detailedAddress.street_number : '',
                        route: this.patientData.detailedAddress.route ? this.patientData.detailedAddress.route : '',
                        locality: this.patientData.detailedAddress.locality ? this.patientData.detailedAddress.locality : '',
                        province: this.patientData.detailedAddress.province ? this.patientData.detailedAddress.province : '',
                        postal_code: this.patientData.detailedAddress.postal_code ? this.patientData.detailedAddress.postal_code : '',
                        unit: this.patientData.detailedAddress.unit ? this.patientData.detailedAddress.unit : '',

                    }
                }
                this.checkAddress()
            }

            // if (val) {
            //     this.focusInput()
            // }
        },

    },
    mounted() {
        this.id = this.visitStore.visitId
        this.dob = this.visitStore.dob
        this.checkAddress()

        if (!this.patientData.hasOwnProperty('detailedAddress')) {
            this.userDataLocal = {
                firstName: this.patient.FirstName ? this.patient.FirstName : '',
                lastName: this.patient.LastName ? this.patient.LastName : '',
                dateofBirth: this.patientData.dateofBirth ? this.patientData.dateofBirth : '',
                healthCardNumber: this.patientData.healthCardNumber ? this.patientData.healthCardNumber : '',
                phoneNumber: this.patientData.phoneNumber ? this.patientData.phoneNumber : '',
                email: this.patientData.email ? this.patientData.email : '',
                country: '',
                street_number: '',
                route: '',
                locality: '',
                province: '',
                postal_code: '',
                unit: '',

            }
        } else {
            this.userDataLocal = {
                firstName: this.patient.FirstName ? this.patient.FirstName : '',
                lastName: this.patient.LastName ? this.patient.LastName : '',
                dateofBirth: this.patientData.dateofBirth ? this.patientData.dateofBirth : '',
                healthCardNumber: this.patientData.healthCardNumber ? this.patientData.healthCardNumber : '',
                phoneNumber: this.patientData.phoneNumber ? this.patientData.phoneNumber : '',
                email: this.patientData.email ? this.patientData.email : '',
                country: this.patientData.detailedAddress ? this.patientData.detailedAddress.country : '',
                street_number: this.patientData.detailedAddress.street_number ? this.patientData.detailedAddress.street_number : '',
                route: this.patientData.detailedAddress.route ? this.patientData.detailedAddress.route : '',
                locality: this.patientData.detailedAddress.locality ? this.patientData.detailedAddress.locality : '',
                province: this.patientData.detailedAddress.province ? this.patientData.detailedAddress.province : '',
                postal_code: this.patientData.detailedAddress.postal_code ? this.patientData.detailedAddress.postal_code : '',
                unit: this.patientData.detailedAddress.unit ? this.patientData.detailedAddress.unit : '',

            }
        }
    },
    methods: {
        closeDialog() {
            this.checkAddress()

            // this.$emit('update:is-bio-dialog-open', false)
        },
        checkAddress() {
            const loader = new Loader({
                // apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
                apiKey: 'AIzaSyD5e-FUz44ioYXmOhU48iKJZ7BXXYNzPMk',
                version: 'weekly',
                libraries: ['places'],
            })
            if (this.isBioDialogOpen) {
                loader.load().then(() => {
                    const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocompleteNew'), {
                        fields: ['address_components', 'formatted_address', 'adr_address', 'place_id', 'url'],
                    })
                    autocomplete.setComponentRestrictions({ country: 'ca' })
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace()

                        // this.userDataLocal.address = place.formatted_address

                        this.userDataLocal.country = place.address_components.find(item => item.types.includes('country'))?.long_name || ''
                        this.userDataLocal.street_number = place.address_components.find(item => item.types.includes('street_number'))?.long_name || ''
                        this.userDataLocal.route = place.address_components.find(item => item.types.includes('route'))?.long_name || ''
                        this.userDataLocal.locality = place.address_components.find(item => item.types.includes('locality'))?.long_name || ''
                        this.userDataLocal.province = place.address_components.find(item => item.types.includes('administrative_area_level_1'),)?.long_name || ''
                        this.userDataLocal.postal_code = place.address_components.find(item => item.types.includes('postal_code'))?.long_name || ''
                        this.userDataLocal.unit = place.address_components.find(item => item.types.includes('subpremise'))?.long_name ?? ''

                        const unitDash = this.userDataLocal.unit ? `${this.userDataLocal.unit} -` : ''
                        this.userDataLocal.formattedAddress = `${unitDash} ${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality}, ${this.userDataLocal.province} ${this.userDataLocal.postal_code} ${this.userDataLocal.country}`
                        this.userDataLocal.formattedAddress = this.userDataLocal.formattedAddress.trim()
                        this.userDataLocal.lastUpdated = this.timeStamp
                        this.refreshKey += 1

                        // this.userDataLocal.address = `${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality} ${this.userDataLocal.province} ${this.userDataLocal.postal_code}`
                    })
                })
            }
        },
        generatedFormattedAddress() {
            const unitDash = this.userDataLocal.unit ? `${this.userDataLocal.unit} -` : ''
            this.userDataLocal.formattedAddress = `${unitDash} ${this.userDataLocal.street_number} ${this.userDataLocal.route} ${this.userDataLocal.locality}, ${this.userDataLocal.province} ${this.userDataLocal.postal_code} ${this.userDataLocal.country}`
            this.userDataLocal.formattedAddress = this.userDataLocal.formattedAddress.trim()
            this.refreshKey += 1
        },
        async onSubmit() {
            const data = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                id: this.visitStore.patientData.id,
                drugPlan: this.userDataLocal.fullName,
                detailedAddress: this.userDataLocal,
            }
            await visitService.updatePatientProfile(data)
            await this.visitStore.setVisit({ id: this.id, dob: this.dob })
            this.$emit('update:is-bio-dialog-open', false)
        },
    },
}
</script>
