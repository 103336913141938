<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <!-- <v-avatar
            color="primary"
            class="v-avatar-light-bg primary--text mb-4"
            size="120"
            rounded
          >
            <v-img
              :src="require('@/assets/images/avatars/01.png')"
            ></v-img>
          </v-avatar> -->

          <span class="mb-2">{{ visitStore.patientData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Profile
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Date of Birth:</span>
              <span class="text--secondary">{{ patientData.dateofBirth }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ patientData.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Phone:</span>
              <span class="text--secondary text-capitalize">{{ patientData.phoneNumber }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Health Card Number:</span>
              <span class="text--secondary">{{ patientData.healthCardNumber }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Allergies:</span>
              <span class="text--secondary">{{ patientData.allergies === '' ? 'None' : patientData.allergies }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Shipping address:</span>
              <span
                v-if="visitStore.patientData.detailedAddress"
                class="text--secondary"
              >{{ patientData.address }}</span>
              <span
                v-if="!visitStore.patientData.detailedAddress"
                class="text--secondary"
              ><v-icon>{{ icons.mdiAlertCircleOutline }}</v-icon></span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <profile-action
            :is-main-bio-dialog-open="isMainBioDialogOpen"
            :emit-step="startingPoint"
            :process-step="6"
          ></profile-action>
          <!-- <v-btn
            color="primary"
            class="me-3"
            @click="isMainBioDialogOpen = !isMainBioDialogOpen"
          >
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <!-- <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
      ></user-bio-edit> -->
    </v-col>
  </v-row>
</template>

<script>

import { mdiAlertCircleOutline, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/extensions
import { useVisitStore } from '@/stores/Visit'
import UserBioEdit from './UserBioEdit.vue'
// eslint-disable-next-line import/extensions
import userViewData from './userViewData'
import createProfileForm from '@/components/Forms/createProfileForm.vue'
import profileAction from '@/components/Forms/profileAction.vue'

export default {
    name: 'UserProfile',
    components: {
        UserBioEdit,
        createProfileForm,
        profileAction
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
        isPlanUpgradeDialogVisible: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const visitStore = useVisitStore()
        const { patientSchema } = userViewData()
        const isMainBioDialogOpen = ref(false)
        const isBioDialogOpen = ref(false)

        // ui
        const resolveCurrentPlanValue = plan => {
            if (plan === 'basic') return '0'
            if (plan === 'standard') return '99'
            if (plan === 'enterprise') return '499'
            if (plan === 'company') return '999'

            return '0'
        }

        return {
            isMainBioDialogOpen,
            visitStore,
            patientSchema,
            resolveCurrentPlanValue,
            isBioDialogOpen,
            icons: {
                mdiAlertCircleOutline,
                mdiBriefcaseVariantOutline,
                mdiCheckboxBlankCircle,
            },
        }
    },
    computed: {
        patientData() {
            return this.patientSchema()
        },
        startingPoint() {
            // if (this.visitStore.visitData.status[0].value === 'complete') {
            //     return 4
            // }

            return 1
        },
    },
    mounted() {
        this.patientSchema()

    // this.patientSchema().then(() => {
    //   this.patientSchema.subscribe(() => {
    //     this.$forceUpdate()
    //   })
    // })
    },
    methods: {
        closeDialogT(val) {
            this.isMainBioDialogOpen = val
        },
    },
}
</script>
