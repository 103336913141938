var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"user-edit-info mb-7",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',[_vm._v("Current Images on file:")]),_c('v-data-table',{attrs:{"items":_vm.visitStore.patientData.upload,"headers":_vm.uploadHeaders},scopedSlots:_vm._u([_vm._l((_vm.uploadHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
              )),function(header){return {key:("item." + (header.value)),fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.dlPDF",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudDownload)+" ")])]}},{key:"item.thumbnailBase64",fn:function(ref){
              var header = ref.header;
              var value = ref.value;
return [(value === '')?_c('v-icon',{staticClass:"me-3",attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilePdfBox)+" ")]):_vm._e(),_c('v-img',{attrs:{"contain":"","max-height":"48","max-width":"96","src":header.formatter(value)}})]}},{key:"item.delete",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"error","medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)})],1)],1)],1)],1),_c('v-card',[_c('v-row',[_c('v-form',{ref:"imageUploadForm",staticClass:"multi-col-validation"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card-title',[_c('p',{staticClass:"tw-text-gray-600 tw-text-base"},[_vm._v(" Please use field below to upload documents to your profile. ")])])],1),_c('v-card-text',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-file-input',{ref:"file",attrs:{"color":"success","loading":_vm.loading,"multiple":"","placeholder":"Select your files","prepend-icon":_vm.icons.mdiCameraOutline,"hide-details":"","outlined":"","show-size":1000,"dense":"","counter":""},on:{"change":function($event){return _vm.uploadFile($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var index = ref.index;
              var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"success","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.images.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1),(_vm.saveSuccess || _vm.invalidForm)?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.saveSuccess)?_c('v-alert',{attrs:{"type":"success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.fileUploadMessage)}})]):_vm._e(),(_vm.invalidForm)?_c('v-alert',{staticClass:"mb-0",attrs:{"color":"error","dark":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }