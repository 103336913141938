<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-alert
      v-if="showAlert"
      type="info"
    >
      Active Visit: <strong>{{ visitStore.visitData.rxDate }}</strong>
    </v-alert>
    <v-card class="mb-7">
      <v-card-title>
        Active Visit: {{ visitStore.visitData.rxDate }}
        <v-spacer>
        </v-spacer>
        <v-btn
          color="primary"
          @click="getAllVisists"
        >
          Refresh
        </v-btn>
      </v-card-title>

      <v-data-table
        v-model="selectedVisit"
        :headers="tableColumnHeaders"
        single-select
        item-key="id"
        :items="visitList.data"
        hide-default-footer
        @click:row="rowClick"
      >
        <template
          #[`item.select`]="{ item, index }"
        >
          <v-btn
            icon
            @click="selectVisit(item, index)"
          >
            <v-icon
              v-if="index === selectedIndex"
              color="info"
              medium
              @click="selectVisit(item, index)"
            >
              {{ icons.mdiCheckboxIntermediate }}
            </v-icon>
            <v-icon
              v-if="index !== selectedIndex"
              color="success"
              medium
              @click="selectVisit(item, index)"
            >
              {{ icons.mdiCheckboxBlankOutline }}
            </v-icon>
          </v-btn>
        </template>

        <template
          #[`item.transferId`]="{ item, index }"
        >
          <!-- :to="{ name: 'transfer'}" -->

          <!-- v-if="item.transferId" -->
          <v-btn
            icon
            color="info"
            @click="selectVisitRoute(item, index)"
          >
            <v-icon
              color="info"
              medium
              @click="selectVisitRoute(item, index)"
            >
              {{ icons.mdiFax }}
            </v-icon>
          </v-btn>
        </template>
        <!-- progress -->
        <template #[`item.status[3].value`]="{item}">
          <v-chip
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveUserProgressVariant(item.status[3].value)"
            :value="item.status[3].value"
          >
            <span>{{ item.status[3].value }}</span>
          </v-chip>
        </template>
        <!-- <template #[`item.rx`]="{item}">
          <div v-html="item.rx"></div>
        </template> -->
        <template
          v-for="header in tableColumnHeaders.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          <v-list-item
            v-for="item,index in header.formatter(value)"
            :key="item"
          >
            <v-list-item-content>
              {{ index+1 }}:  {{ item }}
              <!-- <v-list-item-title>{{ this.visitData[0].visitData.rx }}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
    mdiDotsVertical, mdiFilePdf, mdiPencilOutline, mdiFax, mdiCheckboxBlankOutline, mdiCheckboxIntermediate
} from '@mdi/js'
import { visitService } from '@/api/index.js'
import { useVisitStore } from '@/stores/Visit.js'

export default {
    name: 'VisitList',
    components: {},
    data() {
        return {
            selectedVisit: [],
            visitList: [],
            rowIndex: [],
            showAlert: false,
        }
    },
    setup() {
        const visitStore = useVisitStore()

        const tableColumnHeaders = [
            {
                text: 'Select',
                value: 'select',
                sortable: false,
            },
            { text: 'Visit Date', value: 'rxDate', sortable: false },

            // { text: 'PROGRESS', value: 'status[3].value', sortable: false },
            {
                text: 'Rx',
                value: 'rx',
                sortable: false,
                formatter: item => (item ? item.split('\n') : []),
            },
            {
                text: 'Fax Status',
                value: 'transferId',
                sortable: false,
            },

        ]

        const resolveUserProgressVariant = progrss => {
            if (progrss === 'pending') return 'warning'
            if (progrss === 'complete') return 'primary'
            if (progrss === 'inprogress') return 'success'
            if (progrss === 'action') return 'error'

            return 'secondary'
        }

        return {
            visitStore,
            tableColumnHeaders,
            resolveUserProgressVariant,
            icons: {
                mdiDotsVertical,
                mdiFilePdf,
                mdiPencilOutline,
                mdiFax,
                mdiCheckboxBlankOutline,
                mdiCheckboxIntermediate
            },
        }
    },
    computed: {

        selectedIndex() {
            let data = null
            const cond = this.visitList.data?.length > 0 || false

            if (cond) {
                data = this.visitList.data.findIndex(item => item.id === this.visitStore.visitId)
            } else {
                return -1
            }

            // return the object that has the value this.visitStore.visitId
            return data
        }
    },
    async mounted() {
        await this.getAllVisists()
    },
    methods: {
        async getAllVisists() {
            if (typeof this.visitStore.patientData.id === 'undefined') {
                setTimeout(async () => {
                    this.visitList = await visitService.getAllVisitsByPt({ patientId: this.visitStore.patientData.id })
                    if (this.selectedIndex !== -1) {
                        this.selectedVisit = [this.visitList.data[this.selectedIndex]]
                    }
                }, 500)
            }
            if (typeof this.visitStore.patientData.id !== 'undefined') {
                this.visitList = await visitService.getAllVisitsByPt({ patientId: this.visitStore.patientData.id })
                if (this.selectedIndex !== -1) {
                    this.selectedVisit = [this.visitList.data[this.selectedIndex]]
                }
            }
        },
        rowClick(item, row) {
            // if (this.rowIndex.includes(row.index)) {
            //     this.rowIndex = this.rowIndex.filter(rowIndex => rowIndex !== row.index)
            //     row.select(false)
            // } else {
            //     row.select(true)
            //     this.rowIndex = [row.index]

            //     // this.rowIndex.push(row.index)
            // }
        },
        async selectVisit(item, index) {
            this.rowIndex = [index]
            if (this.visitList.data?.length > 0) {
                this.selectedVisit = [item]
                await this.visitStore.setVisit({ id: this.visitList.data[index].id, dob: this.visitStore.patientData.dateofBirth })
                this.showAlert = true
                setTimeout(() => {
                    this.showAlert = false
                }, 2200)
            }
        },
        async selectVisitRoute(item, index) {
            this.rowIndex = [index]
            if (this.visitList.data?.length > 0) {
                this.selectedVisit = [item]
                await this.visitStore.setVisit({ id: this.visitList.data[index].id, dob: this.visitStore.patientData.dateofBirth })
                this.showAlert = true
                setTimeout(() => {
                    this.showAlert = false
                }, 2200)
            }
            this.$router.push({ name: 'transfer' })
        }
    }
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
